<template>
  <!-- 添加角色页面 -->
  <div class="positionT0L0">
    <p class="fw600">基本信息</p>
    <a-form-model
      ref="ruleForm"
      :model="form"
      :rules="rules"
      labelAlign="left"
      :label-col="{ span: 2 }"
      :wrapper-col="{ span: 5 }"
    >
      <a-form-model-item label="角色名字" prop="roleName" class="por">
        <a-input
          v-model="form.roleName"
          :maxLength="32"
          placeholder="最多输入32个字"
        >
          <div slot="addonAfter">{{ form.roleName.length }}/32</div>
        </a-input>
      </a-form-model-item>
      <a-form-model-item label="角色描述">
        <a-textarea
          placeholder="请输入角色描述"
          v-model="form.roleDescribe"
          :maxLength="300"
          :rows="5"
        />
        <span class="form_span">{{ form.roleDescribe.length }}/300</span>
      </a-form-model-item>
    </a-form-model>
    <p class="fw600">权限设置</p>
    <!-- 树形结构 -->
    <a-tree
      :tree-data="treeData"
      @check="onCheck"
      :checkable="true"
      :checkedKeys="checkedKeys"
    />
    <FooterToolBar :collapsed="sideCollapsed">
      <a-button class="margin_right60" @click="() => $router.go(-1)">
        返回
      </a-button>
      <a-button
        type="primary"
        @click="keepClick"
        :disabled="isDisabled"
        :loading="isDisabled"
      >
        保存
      </a-button>
    </FooterToolBar>
  </div>
</template>
<script>
import FooterToolBar from "@/components/FooterToolbar";
import { baseMixin } from "@/store/app-mixin";
import { message } from "ant-design-vue";
import {
  TreeListApi,
  RoleAddApi,
  RoleUpdateApi,
  RoleDetailApi,
} from "@/request/api/rolePower";
export default {
  mixins: [baseMixin],
  components: { FooterToolBar },
  created() {
    let id = this.$route.params.id;
    // 获取树形图的列表的请求
    TreeListApi().then(({ code, data }) => {
      if (code === 200) {
        let newData = this.transferKey(data);
        this.treeData = newData;
        if (id !== "0") {
          // 获取角色详情
          RoleDetailApi({ id: id }).then(({ code, data }) => {
            if (code == 200) {
              data.arrayShop = data.arrayShop.map((item) => parseInt(item));
              console.log(data);
              this.form = data;
              let arr = [];
              data.menus.map((item) => {
                if (item.children.length > 0) {
                  item.children.map((e) => {
                    if (e.flag && e.selectAll) {
                      arr.push(e.id);
                    }
                    if (e.children.length > 0) {
                      e.children.map((el) => {
                        if (el.flag) {
                          arr.push(el.id);
                        }
                      });
                    }
                  });
                }
              });
              this.checkedKeys = arr;
            }
          });
        }
      }
    });
  },
  data() {
    return {
      isDisabled: false, // 保存的按钮是否禁用
      form: { roleName: "", roleDescribe: "", arrayShop: [] },
      rules: {
        roleName: [
          {
            required: true,
            message: "输入的内容不能为空",
            trigger: "blur",
          },
        ],
      },
      checkedKeys: [], // 树形选中的ID数组
      treeData: [], //树形接口的数据
    };
  },
  methods: {
    // 树形选择框的回调
    onCheck(checkKeys, e) {
      this.checkedKeys = checkKeys;
    },
    arrayShopChange(checkedArr) {
      this.form.arrayShop = checkedArr;
    },
    // 保存按钮
    keepClick() {
      this.isDisabled = true;
      let id = this.$route.params.id;
      if (id == "0") {
        // 添加角色接口
        RoleAddApi({
          menus: this.checkedKeys,
          roleDescribe: this.form.roleDescribe,
          roleName: this.form.roleName,
          arrayShop: this.form.arrayShop,
        }).then(({ code }) => {
          if (code == 200) {
            message.success("保存成功");
            this.$router.push("/rolePower/roleManage");
          }
          setTimeout(() => {
            this.isDisabled = false;
          }, 3000);
        });
      } else {
        // 更新角色
        RoleUpdateApi({
          id: id,
          menus: this.checkedKeys,
          roleDescribe: this.form.roleDescribe,
          roleName: this.form.roleName,
          arrayShop: this.form.arrayShop,
        }).then(({ code }) => {
          if (code == 200) {
            message.success("保存成功");
            this.$router.push("/rolePower/roleManage");
          }
          setTimeout(() => {
            this.isDisabled = false;
          }, 3000);
        });
      }
    },
    // 深度增加一个key字段
    transferKey(array) {
      for (var i = 0; i < array.length; i++) {
        array[i].key = array[i].code;
        if (array[i].children && array[i].children.length > 0) {
          this.transferKey(array[i].children);
        }
      }
      return array;
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.ant-btn-lg {
  margin-right: 60px;
}
/deep/.ant-input {
  resize: none;
}
</style>
